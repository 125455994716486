<template>
    <div class="monitor-index">
        <el-table class="list-table list-table-border" ref="multipleTable" :data="listData" tooltip-effect="dark"
            style="width: 100%" :stripe="true" :border="true" v-loading="tableLoading"
            @selection-change="handleSelectionChange">
            <el-table-column label="监测点位名称" :show-overflow-tooltip="true" prop="MonitorName"></el-table-column>
            <el-table-column label="监测点名称" :show-overflow-tooltip="true" prop="DangerName"></el-table-column>
            <el-table-column label="隐患点类型" prop="DangerType"></el-table-column>
            <el-table-column label="运维单位" :show-overflow-tooltip="true" prop="UnitName"></el-table-column>
            <el-table-column label="隐患点等级" prop="DangerLevel"></el-table-column>
            <el-table-column label="操作" width="160">
                <template slot-scope="scope">
                    <el-button type="success" icon="el-icon-view" size="mini"
                        @click="onShowDetail(scope.row)">详情</el-button>
                    <el-button type="success" icon="el-icon-position" size="mini"
                        @click="onMapLocation(scope.row)">定位</el-button>
                </template>
            </el-table-column>
        </el-table>


        <pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" class="list-pagination"
            :page-size="page.pagesize" hide-on-single-page :total="page.total" :current-page.sync="page.pageindex"
            @current-change="onPageChange" />

        <el-dialog title="监测点位详情" :visible.sync="dialogDetailVisible" width="60%" custom-class="geology-dialog"
            :modal-append-to-body="false" append-to-body>
            <monitor-detail :detailid="activeMonitorDetail.Id" :visible="dialogDetailVisible"></monitor-detail>
            <div slot="footer" class="dialog-footer">
                <router-link :to="'/monitor/edit?id=' + activeMonitorDetail.Id">
                    <el-button type="success" icon="el-icon-edit" size="small">修 改</el-button>
                </router-link>
                <el-button size="small" style="margin-left: 10px" plain type="primary"
                    @click="dialogDetailVisible = false">关 闭</el-button>
            </div>
        </el-dialog>


        <map-location ref="mapLocation" :append-to-body="true" :longitude="activeMonitorDetail.Longitude"
            :latitude="activeMonitorDetail.Latitude"></map-location>

    </div>
</template>
<script>
import { getDangerList, getMonitorList, delMonitor } from "@/api/base";
import monitorDetail from "../../monitor/components/monitorDetail";
import mapLocation from "@/components/mapLocation";
import pagination from '@/components/pagination'
export default {
    data() {
        return {
            listData: [],
            tableLoading: false,
            filterDaterange: "",

            listfilter: {
                dangerid: '',
                fname: "",
            },
            page: {
                pagesize: 10,
                pageindex: 1,
                total: 0,
            },
            dialogDetailVisible: false,
            activeMonitorDetail: '',
        };
    },
    props: {
        dangerid: [String, Number],
    },
    components: {
        monitorDetail,
        mapLocation,
        pagination,
    },
    methods: {
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        dateChange(value) {
            console.log(value);
            console.log(this.filterDaterange);
            if (value) {
                var newdate = [];
                for (let index = 0; index < this.filterDaterange.length; index++) {
                    const element = this.filterDaterange[index];
                    newdate.push(element);
                }
                this.listfilter.fstarttime = newdate[0];
                this.listfilter.fendtime = newdate[1];
                this.getListData();
            } else {
                this.listfilter.fstarttime = "";
                this.listfilter.fendtime = "";
                this.getListData();
            }
        },
        onSearch() {
            this.page.pageindex = 1;
            this.getListData();
        },
        onPageChange(pageindex) {
            this.page.pageindex = pageindex;
            this.getListData();
        },
        getListData() {
            this.tableLoading = true;
            if (this.dangerid) {
                this.listfilter.dangerid = this.dangerid * 1;
            }
            if (this.$route.query.dangerid) {
                this.listfilter.dangerid = this.$route.query.dangerid * 1;
            }

            getMonitorList(
                this.page.pageindex,
                this.page.pagesize,
                this.listfilter
            ).then((resdata) => {
                this.tableLoading = false;
                if (resdata.code == 0) {
                    this.listData = resdata.data.data;
                    this.page.total = resdata.data.count * 1;
                } else {
                    this.$message({
                        type: "error",
                        message: resdata.msg,
                    });
                }
            });
        },
        onListDel(item) {
            this.$confirm("确定要删除选择的数据吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delMonitor(item.Id)
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message.success("删除成功!");
                                this.getListData();
                            } else {
                                this.$message.error("删除失败!" + res.msg);
                            }
                        })
                        .catch((errmsg) => {
                            this.$message.error(errmsg);
                        });
                })
                .catch(() => { });
        },
        onShowDetail(item) {
            this.activeMonitorDetail = item;
            this.dialogDetailVisible = true;
        },
        onMapLocation(item) {
            this.activeMonitorDetail = item;
            this.$refs.mapLocation.show();
        }
    },
    created() {
        this.getListData();
    },
    watch: {
        dangerid: function (newvalue) {
            this.page.pageindex = 1;
            this.listfilter.dangerid = this.dangerid * 1;
            this.getListData();
        },
    },
};
</script>