<template>
  <div class="device-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-date-picker v-model="filterDaterange" type="daterange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" size="medium" style="width: 240px" value-format="yyyy-MM-dd" @change="dateChange">
            </el-date-picker>

            <el-input placeholder="监测点名称、编码" style="width: 300px; margin-left: 10px" size="medium"
              class="form-input-group" v-model="listfilter.fname" @keyup.enter.native="onSearch">
              <el-button slot="append" type="primary" icon="el-icon-search" @click="onSearch">查询</el-button>
            </el-input>
          </el-form>
        </div>
        <div class="action-right">
          <router-link to="/danger/add">
            <el-button type="primary" icon="el-icon-circle-plus-outline" size="medium">新增</el-button>
          </router-link>
        </div>
      </div>
    </div>

    <el-table class="list-table" ref="multipleTable" :data="listData" tooltip-effect="dark" style="width: 100%"
      :stripe="true" :border="false" v-loading="tableLoading" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column label="监测点名称" :show-overflow-tooltip="true" prop="DangerName"></el-table-column>
      <el-table-column label="监测点编码" prop="DangerCode"></el-table-column>
      <el-table-column label="监测点类型" prop="DangerType"></el-table-column>
      <el-table-column label="地址" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.City }}{{ scope.row.County }}{{ scope.row.Towns }}{{ scope.row.Village }}
        </template>
      </el-table-column>
      <el-table-column label="灾害等级" prop="DangerLevel"></el-table-column>
      <el-table-column label="操作" width="320">
        <template slot-scope="scope">
          <el-button type="success" icon="el-icon-view" size="mini" @click="onShowDetail(scope.row)">详情</el-button>
          <el-button type="success" icon="el-icon-position" size="mini" @click="onMapLocation(scope.row)">定位</el-button>
          <router-link :to="'/danger/edit?id=' + scope.row.Id" style="margin-left: 10px">
            <el-button type="success" icon="el-icon-edit" size="mini">修改</el-button>
          </router-link>
          <el-button type="warning" icon="el-icon-error" size="mini" style="margin-left: 10px"
            @click="onListDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" class="list-pagination"
      :page-size="page.pagesize" hide-on-single-page :total="page.total" :current-page.sync="page.pageindex"
      @current-change="onPageChange" />

    <el-dialog title="监测点详情" :visible.sync="dialogDetailVisible" width="60%" custom-class="geology-dialog"
      :modal-append-to-body="true">
      <danger-detail :detailid="activeDangerDetail.Id" :visible="dialogDetailVisible"></danger-detail>
      <div slot="footer" class="dialog-footer">
        <router-link :to="'/danger/edit?id=' + activeDangerDetail.Id">
          <el-button type="success" icon="el-icon-edit" size="small">修 改</el-button>
        </router-link>
        <el-button size="small" style="margin-left: 10px" plain type="primary" @click="dialogDetailVisible = false">关
          闭</el-button>
      </div>
    </el-dialog>

    <map-location ref="mapLocation" :longitude="activeDangerDetail.Longitude"
      :latitude="activeDangerDetail.Latitude"></map-location>
  </div>
</template>
<script>
import { getDangerList, delDanger } from "@/api/base";
import dangerDetail from "./components/dangerDetail";
import mapLocation from "@/components/mapLocation";
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,
      filterDaterange: "",
      listfilter: {
        fname: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },
      dialogDetailVisible: false,
      activeDangerDetail: '',
    };
  },
  components: {
    dangerDetail,
    mapLocation,
    pagination
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    dateChange(value) {
      console.log(value);
      console.log(this.filterDaterange);
      if (value) {
        var newdate = [];
        for (let index = 0; index < this.filterDaterange.length; index++) {
          const element = this.filterDaterange[index];
          newdate.push(element);
        }
        this.listfilter.fstarttime = newdate[0];
        this.listfilter.fendtime = newdate[1];
        this.getListData();
      } else {
        this.listfilter.fstarttime = "";
        this.listfilter.fendtime = "";
        this.getListData();
      }
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getDangerList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delDanger(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => { });
    },
    onShowDetail(item) {
      this.activeDangerDetail = item;
      this.dialogDetailVisible = true;
    },
    onMapLocation(item) {
      this.activeDangerDetail = item;
      this.$refs.mapLocation.show();
    }
  },
  created() {
    this.getListData();
  },
};
</script>